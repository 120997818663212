<template>
  <div class="dialog">
    <div class="dialog-header">
      <div class="dialog-title">
        EDIT CHARACTER
      </div>
    </div>
    <div class="dialog-content pb-0">
      <b-form
        class="character-form"
        @submit.prevent="prepareEdit">
        <b-form-group
          label="Nickname"
          label-for="nickname"
          label-cols="3">
          <b-form-input
            type="text"
            placeholder="Enter Nickname"
            autocomplete="off"
            size="sm"
            v-model.trim="$v.form.nickname.$model"
            :state="!$v.form.nickname.$error"
            @input="$v.form.nickname.$touch()"
          ></b-form-input>
          <b-form-invalid-feedback>
            Nickname is required.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          label="First Name"
          label-for="fname"
          label-cols="3">
          <b-form-input
            type="text"
            placeholder="Enter First Name"
            autocomplete="off"
            size="sm"
            v-model.trim="$v.form.fname.$model"
            :state="!$v.form.fname.$error"
            @input="$v.form.fname.$touch()"
          ></b-form-input>
          <b-form-invalid-feedback>
            First Name is required.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          label="Last Name"
          label-for="lname"
          label-cols="3">
          <b-form-input
            type="text"
            placeholder="Enter Last Name"
            autocomplete="off"
            size="sm"
            v-model.trim="$v.form.lname.$model"
            :state="!$v.form.lname.$error"
            @input="$v.form.lname.$touch()"
          ></b-form-input>
          <b-form-invalid-feedback>
            Last Name is required.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          label="Email"
          label-for="email"
          label-cols="3">
          <b-form-input
            type="email"
            placeholder="Enter Email"
            autocomplete="off"
            size="sm"
            v-model.trim="$v.form.email.$model"
            :state="!$v.form.email.$error"
            @input="$v.form.email.$touch()"
          ></b-form-input>
          <b-form-invalid-feedback>
            <span v-if="!$v.form.email.required">Email is required.</span>
            <span v-if="!$v.form.email.email && $v.form.email.required">
              This requires a valid email address.
            </span>
            <span v-if="!$v.form.email.uniqueEmail && $v.form.email.email &&
              $v.form.email.required">
              Email is already registered.
            </span>
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          class="align-items-center"
          label="Tier"
          label-for="tier"
          label-cols="3">
          <b-form-checkbox
            v-model="form.tier"
            v-for="tier in tierOptions"
            :id="`${tier.value}`"
            :key="tier.value"
            :value="tier.value"
            @change="checkTier(tier.value)"
            inline>
            {{ tier.text }}
          </b-form-checkbox>
        </b-form-group>

        <b-form-group
          class="align-items-center"
          label="Profile Photo"
          label-for="photo"
          label-cols="3">
          <b-form-file
            accept="image/jpeg, image/png"
            v-model="form.photo"
            @change="$v.form.photo.$touch()"
            :state="!$v.form.photo.$error"
          ></b-form-file>
          <b-form-invalid-feedback :state="!$v.form.photo.$error">
            <span v-if="!$v.form.photo.required">
              Profile Photo is required.
            </span>
            <span v-if="!$v.form.photo.validPhoto && $v.form.photo.required">
              Invalid file type.
            </span>
          </b-form-invalid-feedback>
        </b-form-group>

        <div class="d-flex justify-content-end">
          <b-button
            type="submit"
            variant="outline-success"
            :disabled="$v.form.$invalid || !isFormEdited">
            Submit
          </b-button>
          <b-button
            class="ml-1"
            type="button"
            variant="outline-danger"
            @click="$emit('close')">
            Cancel
          </b-button>
        </div>
      </b-form>
    </div>

    <AppLoaderAdmin :isLoading="loading" />
  </div>
</template>

<script>
  import _ from 'lodash';
  import { required, email } from 'vuelidate/lib/validators';

  export default {
    props : {
      userData : {
        type     : Object,
        required : true,
      },
    },
    data() {
      return {
        loading        : false,
        uniqueEmail    : true,
        uniqueNickname : true,
        isFormEdited   : false,
        form           : {
          nickname : this.userData.nickname,
          fname    : this.userData.firstname,
          lname    : this.userData.lastname,
          email    : this.userData.email,
          tier     : (this.userData.tier == 1) ? 1 : [ 0, 1 ],
          photo    : null,
        },
        tierOptions : [
          { text : 'Free', value : 0 },
          { text : 'Champion', value : 1 },

          // { text : 'All Star', value : 2 },
          // { text : 'MVP', value : 3 },
        ],
        validPhoto  : false,
        progress    : null,
        updateCount : 0,
      }
    },
    watch : {
      'form.nickname'(val) {
        this.uniqueNickname = true;
        if (val && val !== this.userData.nickname)
          this.checkNickname();
      },
      'form.email'(val) {
        this.uniqueEmail = true;
        if (val && val !== this.userData.email)
          this.checkEmail();
      },
      'form.photo'(val) {
        this.validPhoto = true;
        if (val && !val.type.startsWith("image/")) {
          this.$nextTick(() => {
            this.validPhoto = false;
          });
        }
      },
    },
    components : {
      'AppLoaderAdmin' : () => import('@/components/layout/AppLoaderAdmin'),
    },
    methods : {

      /**
       * Prepare Edit
       */
      async prepareEdit() {
        this.loading = true;

        // Collate Update Fields Params
        const params = {};
        if (this.form.nickname != this.userData.nickname)
          params.nickname = this.form.nickname;

        if (this.form.fname != this.userData.firstname)
          params.firstname = this.form.fname;

        if (this.form.lname != this.userData.lastname)
          params.lastname = this.form.lname;

        if (this.form.email != this.userData.email)
          params.email = this.form.email;

        const premiumType = (this.form.tier == 1) ? 1 : 0;
        if (premiumType != this.userData.tier) {
          // eslint-disable-next-line camelcase
          params.premium_type = premiumType;
        }

        // Update Profile Photo
        if (this.form.photo) {
          const formData = new FormData();
          formData.append('file', this.form.photo);
          formData.append('userId', this.userData.user_id);

          try {
            const upload = await this.$http.post(
              'api/upload/user/profile',
              formData, {
                onUploadProgress : event =>
                  this.progress = Math.round(event.loaded * 100 / event.total),
              });

            if (upload) {
              if (!_.isEmpty(params))
                this.editCharacter(params);
              else
                this.successNotif();
            }
          } catch(err) {
            this.loading = false;
            this.$notify({
              group : 'notif',
              type  : 'error',
              title : 'Failed!',
              text  : 'Oops! Something went wrong!',
            });
          }
        } else {
          if (!_.isEmpty(params))
            this.editCharacter(params);
          else
            this.successNotif();
        }
      },

      /**
       * Edit Character
       */
      async editCharacter(params) {
        this.$http.put('api/user', {
          userId       : this.userData.user_id,
          updateFields : params,
        }).then(() => {
          this.successNotif();
        }).catch(() => {
          this.loading = false;
          this.$notify({
            group : 'notif',
            type  : 'error',
            title : 'Failed',
            text  : 'Oops! Something went wrong!',
          });
        });
      },

      /**
       * Show Success Notification
       */
      successNotif() {
        this.loading = false;
        this.$parent.$emit('reload-table');
        this.$emit('close');
        this.$notify({
          group : 'notif',
          type  : 'success',
          title : 'Success',
          text  : `Edited <b>${this.form.nickname}</b> succesfully!`,
        });
      },

      /**
       * Check if form is edited
       * @param newVal
       */
      formUpdated(newVal) {
        this.isFormEdited = false;
        const tier = (this.form.tier == 1) ? 1 : 0;

        // TODO: change tier update checking
        if (newVal.nickname !== this.userData.nickname ||
          newVal.fname !== this.userData.firstname ||
          newVal.lname !== this.userData.lastname ||
          newVal.email !== this.userData.email ||
          tier != this.userData.tier ||
          newVal.photo)
          this.isFormEdited = true;
      },

      /**
       * Check if Nickname is already used
       */
      // eslint-disable-next-line no-undef
      checkNickname : _.debounce(function () {
        this.$http.get('api/user/nickname', {
          params : {
            email    : this.form.nickname,
            nickname : this.form.nickname,
          },
        }).then(response => {
          // swapped value since api returns true
          //  if nickname exists hence nickname is not unique
          this.uniqueNickname = (response.data) ? false : true;
        }).catch(() => {});
      }, 500),

      /**
       * Check if Email is already registered
       */
      // eslint-disable-next-line no-undef
      checkEmail : _.debounce(function () {
        this.$http.get('api/user/email', {
          params : {
            email : this.form.email,
          },
        }).then(response => {
          // swapped value since api returns true
          //  if email exists hence email is not unique
          this.uniqueEmail = (response.data) ? false : true;
        }).catch(() => {});
      }, 500),

      /**
       * Check Tier
       * @param tier
       */
      checkTier(tier) {
        if (tier == 0) {
          if (this.form.tier == 0)
            this.form.tier = [ 0, 1 ];
        }

        if (tier == 1) {
          if (this.form.tier == 0)
            this.form.tier = [];
        }
      },
    },
    created() {
      this.$watch('form', this.formUpdated, {
        deep : true,
      });
    },
    validations() {
      return {
        form : {
          nickname : {
            required,
            uniqueNickname() {
              return this.uniqueNickname;
            },
          },
          fname : { required },
          lname : { required },
          email : {
            required,
            email,
            uniqueEmail() {
              return this.uniqueEmail;
            },
          },
          tier  : { required },
          photo : {},
        },
      }
    },
  }
</script>